// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//const url = 'https://test.ada.tmp.aturis.org';
const url = 'https://ada.tmp.aturis.org';
const logStorageKey = 'tmp.ada.log'

export const MIN_STRING_LENGTH = 7

export const environment = {
  production: false,
  logging: {
    logLevels: [
			{
				loggerName: 'root',
				logLevel: 'DEBUG'
			}
		],
		localStorageAppender: {
			localStorageKey: logStorageKey,
			threshold: 'INFO',
			maxMessages: 250
		},
    ajaxAppender: {
      url: `${url}/Interface?app_log=1`,
      threshold: 'DEBUG',
      batchSize: 10,
      timerInterval: 10000
    },
    browserConsoleAppender: {
      threshold: 'ALL'
    }
  },
  simpleImageUpload: true,
  apiUrl: `${url}/Interface`,
  tusUrl: `${url}/tus`,
  appName: "ADABETA",
  fetchNewDataInterval: 60000,
  sendToServerInterval: 30000,
  googleApiKey: "AIzaSyAZOhxFtfv7MFUP45RjlEm10CyMgCFcU8w",
  logStorageKey
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
